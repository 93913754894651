.paypal {
    height: 15.625rem;
    max-width: 60.625rem;

    @include m_query($boo-sm) {
        height: 13rem;
      }

    &__content {
        @apply bg-primary-dark-blue-1;
        @apply  text-white;

        max-width: 100%;

        @media (min-width: $tw-3xl) {
            max-width: 19.688rem;
        }

        &--text {
          font-size: 1.0625em;

          @include m_query($boo-sm) {
            font-size: 0.875em;
            line-height: 20px;
          }
        }
    }

    &__image {
        width: 40%;

        @media (min-width: $tw-3xl) {
          width: 11.25rem;
        }

        @include m_query($boo-sm) {
            min-width: 9rem;
          }
    }
}

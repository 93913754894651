.banner {
    position: relative;
    width: 100%;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     left: 0px;
    //     top: 0px;
    //     background: var(--gd-side-shadow);
    //     width: 100%;
    //     height: 100%;
    // }


    &__lines {
        -webkit-clip-path: inset(0px 0px 30% 0px);
        clip-path: inset(0px 0px 30% 0px);
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: 0.3;
        height: 140%;
        z-index: 1;
    }

    &__photo { 
        position: absolute;
        object-fit: cover;
        right: 0px;
        height: auto;
        clip-path: inset(0px 0px 262px 0px);
        -webkit-clip-path: inset(0px 0px 262px 0px);

        &_multimedia {
            position: absolute;
            object-fit: cover;
            right: 15%;
            top: -35%;
            clip-path: inset(129px 0px 650px 0px);
            -webkit-clip-path: inset(129px 0px 650px 0px);
        }

        &_contacto{
            position: absolute;
            object-fit: cover;
            right: 20%;
            top: 0%;
            height: 100%;
        }
    }
}
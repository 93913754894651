.header {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.35);

  @include m_query($boo-lg) {
    display: none;
  }

  @apply bg-white;

  &--gd-dark_blue {
    background: var(--gd-dark-blue);
    border-bottom: 3px solid var(--c-primary-red);
  }

  &__top_bar {
    &__date {
      font-size: var(--fs-1);
      font-weight: 400;
    }
  }

  &__main {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 60.625rem;
    min-height: 7.625rem;
    padding: 1rem 0;
  }

  &__navbar {

    &__items {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;

      @apply text-white;
    }

    &--bg-blue {
      @apply bg-primary-blue;
      box-shadow: 0 5px 14px rgba(0, 0, 0, 0.26);
      border-bottom: 3px solid var(--c-primary-red);
    }

    &__item {
      height: 120%;
      display: flex;
      font-size: var(--fs-1);
      font-weight: 600;
      @apply text-white;
      transition: all 0.3s ease-in-out;

      @include m_query($boo-xl) {
        font-size: 0.875em;
      }

      &:hover {
        background: var(--c-bg-hover);
      }

      a {
        height: 100%;
        width: 100%;
        display: flex;
        padding: 0 1rem;
        align-items: center;
        margin-top: -2px;

        @include m_query($boo-xl) {
          padding: 0 0.5rem;
        }
      }

    }
  }
}

@media only screen and (max-width:1440px) {
  .header__navbar__item a {
    padding: 0rem 0.5rem;
  }
}

.item {

  &--hover-red {
    &:hover {
      @apply bg-primary-light-red;
    }
  }

}

.home_mobile_header {
  display: none;

  @include m_query($boo-lg) {
    display: block;
  }
}

.internal_section_header {
  z-index: 100;
  position: relative;
  @apply bg-primary-dark-blue-1;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.35);
  height: 4.063rem;

  @include m_query($boo-sm) {
    height: 3.125rem;
  }

  &.note {
    height: 8.125rem;

    @include m_query($boo-xl) {
      height: 4.063rem;
    }

    @include m_query($boo-sm) {
      height: 3.125rem;
    }

  }

  &__main {
    &--logo {
      max-width: 17.5rem;

      @include m_query($boo-sm) {
         max-width: 14.375rem;
      }
    }

    &--title {
      @apply font-ff-primary;
      @apply font-semibold;
      font-size: 1.75em;
      line-height: 35px;
      text-transform: uppercase;

      @include m_query($boo-sm) {
        font-size: 1em;
        line-height: 25px;
      }
    }
  }
}

.search {
  width: inherit;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;

  &--show {
    pointer-events: auto;
    opacity: 1;
  }

  &__input {
    background: transparent;
    padding-bottom: 0.20rem;
    color: #fff;
    border-bottom: #fff solid 0.3px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.9375em;
    }

    &:focus {
      outline: none;
    }
  }
}

.search--show~.icons {

  .open_icon {
    display: none;
  }

  .close_icon {
    display: block;
  }

}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  animation-duration: 1.5s;
}

.menu_burger {
  position: fixed;
  display: flex;
  top: -150%;
  height: auto;
  width: 100%;
  padding: 3.438rem 3.75rem;
  background: var(--bg-blue-menu);
  z-index: 90;
  border-bottom: 3px solid #FFFFFF;

  @include m_query($boo-sm) {
    width: 20rem;
  }

  &--open {
    --top_header: 0;
    top: var(--top_header);
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    animation-duration: 600ms;
  }

  &__logo {
    display: flex;
    margin-left: -0.25rem;
    margin-bottom: 2rem;

    @include m_query($boo-sm) {
      margin-bottom: 1rem;
    }
  }

  &__nav {
    overflow-y: auto;
    width: 100%;
    display: block;

    @include m_query($boo-sm) {
      width: 99%;
    }

    &::-webkit-scrollbar {
      width: 12px;

      @include m_query($boo-sm) {
        width: 10px;
      }

    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-primary-dark-blue;
      border-radius: 100px;
    }

    &>div {
      width: 100%;
      max-width: 1578px;
      margin: auto;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      display: grid;

      & .line-right {
        border-right: 0.4px solid rgba(255, 255, 255, 0.4);
        margin: 0rem 1.5rem;
      }
    }

    &__item {
      @apply text-white;
      padding: 0.5rem 0;
      font-size: var(--fs-1);
      line-height: 23px;
      font-weight: 600;
      text-transform: uppercase;

      @include m_query($boo-sm) {
        font-size: 1.125em;
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .menu_burger {
    height: 100vh;
    padding: 1rem 2.75rem;
    padding-bottom: 5rem;
    width: 100% !important;

    &__nav {
      height: 100%;

      &>ul {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 0;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        padding-left: 1rem !important;
        padding-right: 1rem;

        & .line-right {
          border-right: none;
          border-bottom: 0.4px solid #FFF;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width:1482px) {
  .line-right:last-child {
    border-right: none !important;
  }
}

@media only screen and (min-width:1600px) {
  .menu_burger__nav {
    &>div {
      grid-template-columns: repeat(min(6), minmax(170px, 1fr));
      padding-left: 4rem;
    }
  }
}

.header__top_bar {
  @apply bg-primary-blue;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    top: 0;
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    top: -100%;
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    top: 0;
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    top: -100%;
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: -100%;
  z-index: 9;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.364);
  transition: all 1s ease;
}

.menu_burger--open~.overlay {
  opacity: 1;
  transition: all 1s ease;

  @include m_query($boo-sm) {
    left: 20rem;
  }
}

.sticky_nav {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: -90px;
  transition: top 0.6s ease;

  &--show {
    top: 0;
  }
}

#progress-reading {
  --progress: 0;
  height: 6px;
  width: var(--progress);
  background-color: #E12028;
}

// Animation Hamburguer
.checkbox {
  opacity: 0;
  cursor: pointer;
  position: relative;
  z-index: 2;
  height: 20px;
  width: 20px;
}

.hamburger {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 0.5rem;

  @include m_query($boo-xs) {
    padding: 0.5rem;
  }
}

.line {
  position: absolute;
  width: 20px;
}

.line-main::before,
.line-main::after,
.line-split::before,
.line-split::after {
  content: '';
  position: absolute;
  width: inherit;
  height: 2px;
  background-color: #FFF;
  transition: transform 400ms;
}

.line-main::before {
  transform: translateY(-7px);
}

.line-main::after {
  transform: translateY(7px);
}

.line-split::before,
.line-split::after {
  width: 10px;
  transition: transform .5s, opacity .5s;
}

.line-split::after {
  transform: translateX(10px);
}

.checkbox:checked~.line-main::before {
  transform: rotate(45deg);
}

.checkbox:checked~.line-main::after {
  transform: rotate(-45deg);
}

.checkbox:checked~.line-split::before {
  transform: translateX(-10px);
  opacity: 0;
}

.checkbox:checked~.line-split::after {
  transform: translateX(20px);
  opacity: 0;
}

.main_news {
    display: grid;
    gap: 0.875rem;

    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (min-width: $tw-3xl) {
      grid-template-columns: [line1] 2fr [line2] 1.2fr [line3] 1fr;
    }

    @include m_query($boo-lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (width: $boo-md) {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    @include m_query($boo-md) {
       grid-template-columns: repeat(1, minmax(0, 1fr));
    }


  .dual_video {

   order: 2;
   grid-column: span 3;

   @include m_query($boo-sm) {
    order: initial;
 }

   &--container {
     flex-direction: row;

     .splide__slide {
       iframe {
         min-height: 325px;
       }
     }
  }
    @media (min-width: $tw-3xl) {
      order: initial;
      grid-column: span 1;

      &--container {
        flex-direction: column;

        .splide__slide {
          iframe {
            min-height: 100%;
          }
        }
    }
    }

    @include m_query($boo-lg) {
      grid-column: span 1;

      &--container {
        flex-direction: column;
        justify-content: space-between;

        .splide__slide {
          iframe {
            min-height: 250px;
          }
        }
      }
   }
  }

  .card__other_news__image {
    @include m_query(1280px) {
        height: 140px;
        min-height: 100%;
    }

  }

  .card__other_news:last-child {
     @include m_query(1280px) {
         padding-bottom: 1rem;
     }
  }
}


.story_details {
  flex-grow: 1;
  flex-basis: 1107px;
  max-width: 70%;
  display: grid;
  grid-template-columns: [line1] 10rem [line2] 1fr;

  @include m_query($boo-md) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media only screen and (max-width: 1280px) {
    max-width: 100%;
  }
}

#contact-form {
    & input {
        &:focus {
            outline-width: 0;
        }
    }

    & textarea {
        &:focus {
            outline-width: 0;
        }
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ff-secondary);
  font-weight: 600;
  line-height: calc(1em + 0.5rem);
  margin-bottom: 0;
}

a,
p {
  line-height: calc(1em + 0.5rem);
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

.secondary_news {
  .dual_video {
    @include m_query(80em) {
      order: 2;
    }
    @include m_query($boo-md) {
      order: initial;
    }
  }
}

.story__body {
  & > p iframe {
    height: 28.125rem;
    width: 100% !important;

    @include m_query($boo-md) {
      height: 18.75rem;
    }
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 100% !important;
  }
}

.live {
  iframe {
    height: 43vw;
    min-height: 31.25rem;
    max-height: 37.5rem;

    @include m_query($boo-md) {
      min-height: 13.75rem;
    }
  }
}

.news_box {
  &__iframe {
    iframe {
      width: 100%;
      height: 30rem;

      @include m_query($boo-sm) {
        height: 15.625rem;
      }
    }
  }
}

.story_details h1 {

  @include m_query($boo-sm) {
    font-size: 1.75rem !important;
  }

}

.story_opinion {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #CFCFCF;

  &__author-name {
    font-family: var(--ff-primary);
    font-size: 1.375rem;
    font-weight: 600;
  }

  &__author-image {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  &__source {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 0.75rem;
  }
}

.sticky-ad-mobile {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  justify-content: space-evenly;
  padding-top: 1rem;
}

.sticky-bar-mobile {
  display: none;
  position: absolute;
  flex-direction: column;
  top: 5px;
  right: 10px;
  justify-content: space-evenly;

  a {
    height: 50px;
    margin-top: -25px;
    visibility: hidden;
    opacity: 0;
    transition: all 350ms ease;

    &.show-icons {
      margin-top: -5px;
      visibility: visible;
      opacity: 1;
      transition: all 500ms ease;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #002045;
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
      width: 18px;
      height: 18px;

      &--share {
        position: absolute;
        width: 22px;
        height: 22px;
        transition: all 450ms ease;
      }

      &--close {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: all 350ms ease;
      }
    }

    &.sticky-bar-open {
      .sticky-bar-mobile__icon__img--share {
        visibility: hidden;
        opacity: 0;
      }

      .sticky-bar-mobile__icon__img--close {
        visibility: visible;
        opacity: 1;
        transition: all 1s ease;
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media only screen and (max-width: 440px) {
  .set__dates {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .sticky-bar-mobile {
    display: flex;
  }

  .news_box__image > img {
    height: auto;
  }

  .news_box__image {
    margin: 0 -1rem;
  }
}

.notification__animation {
  animation: vibrate 1s linear infinite;
}

.notification {
  left: -100%;
  opacity: 0;
  transition: left 500ms ease-in-out 300ms, opacity 300s ease-in-out 0ms;
  z-index: 9999999;

  &.active {
    left: 1%;
    opacity: 1;
    transition: left 1s ease-in-out 0ms, opacity 1.2s ease-in-out 0ms;
  }

  &__subscribe {
    background-color: #175DAF;
  }
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px) rotate(-1deg);
  }
  50% {
    transform: translateX(10px) rotate(2deg);
  }
  75% {
    transform: translateX(-5px) rotate(-1deg);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: $boo-lg) {
  .set__copyright {
    flex-direction: column-reverse;
    gap: 0.5rem;
  }
}

.story__detail_aside {
  @media only screen and (max-width: 640px) {
    .widget-video-stream {
      display: none !important;
    }

    .other_news {
      display: none !important;
    }
  }
}

.story__detail_aside {
  flex-grow: 1;
  flex-basis: 278px;
  width: 30%;

  @media only screen and (min-width: 640px) {
    display: none;
  }

  @media only screen and (min-width: 1280px) {
    display: block;
  }
}

.body_description {
  & p {
    font-family: var(--ff-secondary);
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #222;
  }

  &.text_white {
    & > p {
      color: #FFF !important;
    }
  }
}

.authors {
  & span, li {
    font-family: var(--ff-secondary);
    font-style: normal;
    font-weight: 600;
    font-size: var(--fs-small);
    line-height: 16px;
    color: #FFFFFF;
  }
}

.story__body {
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  color: #222222;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  & p {
    line-height: 34px;
    font-weight: 500;
    margin: 0 3.125rem;
    width: 100%;

    & img {
      width: 100%;
    }
  }

  & a {
    color: #005B7A;
    font-weight: bold;
  }

  h1, h2, h3, h4, h5, h6 {
    width: 100%;
    font-weight: 700;
    font-size: 1.375rem;
  }

  h2 {
    color: #07093D !important;
  }

  h3 {
    font-weight: 400;
    color: #0C0F66 !important;
  }

  h4 {
    color: #0B108C !important;
  }

  h5 {
    color: #0E4EAD !important;
  }
}

@media only screen and (min-width: 1024px) {
  .story__body {
    & p {
      padding: 0 3.125rem;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0 3.125rem;
      padding: 0 3.125rem;
      width: 100%;
    }

  }
}

.static_page {
  &__content {
    p {
      font-family: var(--ff-secondary);
      font-weight: 300;
      font-size: var(--fs-1);
      line-height: 25px;
      color: #222222;
    }

    h5 {
      font-size: var(--fs-2);
      font-weight: 600;
      color: #222222;
    }
  }
}

.card {
  &__other_news {

    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    @include m_query($boo-sm) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &__wrapper {
      @include m_query($boo-sm) {
        width: 30%;
      }

      @media (max-width: 440px) {
        width: 50% !important;
      }
    }

    &__content {
      @include m_query($boo-sm) {
        width: 100%;
      }
    }

    &__image {
      max-width: 160px;
      min-height: 185px;
      height: 100%;
      object-fit: cover;

      @include m_query($boo-md) {
        max-width: 250px;
      }

      @include m_query($boo-sm) {
        max-width: 100%;
        min-height: 120px;
      }

      &-small {
        max-width: 160px;
        min-height: 140px;
        height: 100%;
        object-fit: cover;

        @include m_query($boo-sm) {
          max-width: 100%;
        }
      }
    }

  }

  &__main {
    align-items: center;
    color: var(--c-text-white);
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.75rem 2rem 2rem;

    @include m_query($boo-md) {
      flex-direction: column-reverse;
      padding: 1.5rem;
    }

    &--gd-red {
      background: var(--gd-red);
    }

    &--gd-light_blue {
      background: var(--gd-light-blue);
    }

    &--size {
      &-sm {
        min-height: 18.75rem;
      }

      &-md {
        min-height: 21.188rem;
      }

      &-xl {
        min-height: 45.5rem;

        @include m_query($boo-md) {
          min-height: 25.5rem;
        }
      }
    }

    &__title {
      font-family: var(--ff-primary);
      font-style: normal;
      font-weight: 600;
      font-size: 2.125em;
      line-height: 38px;
      color: #FFFFFF;
      overflow: hidden;
      background: linear-gradient(to right, #E12028, #E12028 50%, #FFF 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 100%;
      background-position: 100%;
      transition: background-position 275ms ease;
      text-decoration: none;

      @include m_query($boo-sm) {
        font-size: 1.5em;
        line-height: 30px;
      }

      &:hover {
        background-position: 0 100%;
      }

      &--text-black {
        color: #000;
        -webkit-text-fill-color: #000;
        background: none;
      }
    }

    &__content {
      @include m_query($boo-md) {
        margin-top: 1.5rem;
      }

      &__title {
        @apply font-ff-primary;
        font-weight: 600;
        font-size: 1.625rem;
        line-height: 2.063rem;
        margin: 1rem 0;

        @include m_query($boo-sm) {
          font-size: 1.25em;
          margin: 0.5rem 0;
        }
      }

      &-description {
        @apply font-ff-secondary;
        font-weight: 300;
        font-size: 0.938rem;
        line-height: 1.25rem;

        @include m_query($boo-sm) {
          font-size: 0.875em;
        }
      }
    }

    &__wrapper {
      max-width: 25rem;
      height: 14.5rem;
      overflow: hidden;
      width: 100%;

      @include m_query($boo-md) {
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: 14.5rem;
      }
    }
  }

  &__news {

    &__image {

      &-sm {
        max-height: 8.313rem;
      }

      &-md {
        max-height: 11.125rem;
      }
    }

    &__title {
      font-size: 1.625rem;
      @include m_query($boo-sm) {
        font-size: 1.25em;
      }
    }
  }

  &__bg {
    background: #DEE6EE;
    height: 100%;

    &__size_md {
      min-height: 18.75rem;

      @include m_query($boo-md) {
        min-height: 16.325rem;
      }

    }

    &__size_sm {
      min-height: 14.375rem;
      @include m_query($boo-md) {
        min-height: 12.325rem;
      }
    }

    &__size-xl {
      min-height: 45.5rem;

      @include m_query($boo-md) {
        min-height: 25.5rem;
      }
    }
  }

  &__home {
    &__content {
      padding: 2.188rem;
      background: var(--gd-black);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      @include m_query($boo-sm) {
        padding: 1.5rem;
      }
    }
  }

  &__bg_image {
    &__content {
      padding: 2rem;
      background: var(--gd-black);
      min-height: 17.813rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &__cover {
    p {
      font-family: var(--ff-secondary);
      font-style: normal;
      font-weight: 300;
      font-size: 0.9375em;
      line-height: 20px;
      color: #222222;
    }
  }

  &__small {
    &--image_size-sm {
      max-height: 8.313rem;

      @include m_query($boo-md) {
        max-height: 14.125rem;
      }

      @include m_query($boo-sm) {
        max-height: 11.125rem;
      }
    }

    &--image_size-md {
      max-height: 11.125rem;

      @include m_query($boo-md) {
        max-height: 14.125rem;
      }

      @include m_query($boo-sm) {
        max-height: 11.125rem;
      }
    }
  }

  &__opinion {
    background: #3E5F87;
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.25rem;
      height: 1.75rem;
    }

    &__author {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        font-family: var(--ff-primary);
        font-weight: 600;
        font-size: 1.125rem;
      }

      &-image {
        width: 104px;
        height: 104px;
        border-radius: 50%;
      }
    }

    &__content {
      position: relative;
      min-height: 12.5rem;
      margin-top: 1rem;
      padding: 2.2rem 1.2rem 1.2rem 1.2rem;

      p {
        font-family: var(--ff-secondary);
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 25px;
      }
    }

    &_quotes {
      position: absolute;
      top: 10px;
      left: -5px;
    }
  }

  &__ol {
    position: relative;

    & > img {
      min-height: 300px;
      width: 100%;
    }

    &__headline {
      background: var(--gd-black);
      color: #FFFFFF;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      bottom: 0;
      padding: 0 20px 30px 20px;
      position: absolute;
      text-align: center;
      height: 100%;
      width: 100%;

      &:hover {
        color: lightgrey;
      }
    }

  }
}

.object-top {
  object-position: top;
}

.object-right {
  object-position: right;
}

.object-bottom {
  object-position: bottom;
}

.object-middle {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-top {
  object-position: left top;
}

.object-right-top {
  object-position: right top;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-left-bottom {
  object-position: left bottom;
}

.article_fullwidth_image {
  background-color: #F0F4F8;
  display: none;
  height: 270px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  min-width: 100% !important;
  min-height: auto !important;

  .divimage {
    background-attachment: fixed;
    background-position-y: bottom;
    background-repeat: repeat-y;
    display: block;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: 930px;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.simpleParallax {
  top: 50%;
  position: relative;
  overflow: hidden;
  transform: translateY(-50%);
  height: 100%;
}

.parallax-image {
  width: 100%;
  display: none;
  transform: translate3d(0px, 0px, 0px) scale(1.3);
  will-change: transform;
}

.div_parallax {
  min-width: 100% !important;
  min-height: auto !important;
}

.story__body > div {
  //display: none;
  width: 100% !important;
  min-width: 100% !important;
  min-height: auto !important;

  & iframe {
    min-width: 100% !important;
    max-width: 930px !important;
    width: 100% !important;
  }
}


@media only screen and (min-width: 1280px) {
  .article_fullwidth_image {
    display: flex;
  }

  //.story__body > div {
  //  display: flex;
  //}
}

.ad {
  display: flex;
  justify-content: center;

  @include m_query($boo-xs) {
    max-width: 280px !important;
    min-width: auto !important;
  }

  &--align-middle{
    margin: 0 auto;
  }

  &--w-728 {
   width: 728px;
   min-width: 728px;
  }

  &--w-336 {
    width: 336px;
    min-width: 336px;
  }
  &--w-320 {
    width: 320px;
    min-width: 320px;
  }
  &--w-160 {
    width: 160px;
    min-width: 160px;
  }
  &--w-300 {
    width: 300px;
    min-width: 300px;
  }

  &--w-970 {
    width: 970px;
    min-width: 970px;
  }

  &--h-90 {
    height: 90px;
  }
  &--h-100 {
    height: 100px;
  }
  &--h-50 {
    height: 50px;
  }

  &--h-250 {
    height: 250px;
  }

  &--h-280 {
    height: 280px;
  }

  &--h-480 {
    height: 480px;
   }

  &--h-600 {
   height: 600px;
  }

  @media only screen and (max-width:1599px) {
    &-aside {
      display: none;
    }
  }
}

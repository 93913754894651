// ROJO VIEJO
.index-center-ad {
  display: none;
  text-align: center;
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto;
    max-width: 768px !important;

    .modal-content {
      position: relative;
      height: 700px;
      width: 100%;

      & img {
        width: 100%;
      }

      .ads-timer {
        border: 2px solid #000;
        background: rgba(0,0,0,.2);
        border-radius: 5px;
        color: #000;
        font-size: 15px;
        font-weight: bold;
        padding: 5px 10px;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
}

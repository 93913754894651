.slider {

    &_main {
    position: relative;
    height: 100%;
    min-height: 600px;

    &--img {
      object-fit: cover !important;
      position: relative;
      height: 100%;
      width: 100%;
      z-index: -1;

      &-gradient {
        background-image: var(--gd-black);
        width: 100%;
        height: 100%;
        position: relative;
        &-sm {
          background-image: var(--gd-black-sm);
        }
      }
    }

    &__content {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: flex-end;
      opacity: 1;
      height: 100%;
      bottom: 15%;

      @include m_query($boo-md) {
        bottom: 19%;
      }

      &--description {
        @include m_query($boo-sm) {
            display: none;
          }
        &>p {
          font-family: var(--ff-secondary);
          font-weight: 300;
          font-size: 0.9375em;
          line-height: 20px;
          color: var(--c-text-white);
        }
      }
    }

    &__more_news {
      position: absolute !important;
      bottom: 3%;
      width: 100%;

      &--notice {
        display: flex;
        @include m_query($boo-md) {
        flex-direction: column;
        }

        & .slider_main--dates {
          color: #BBB;
          @include m_query($boo-xs) {
              display: none;
            }
        }

        &>img {
          height: 4.688rem;
          width: 5.625rem;
          object-fit: cover;
          opacity: 0.5;
          margin-right: 0.875rem;
          @include m_query($boo-md) {
            height: 3.125rem;
            width: 100%;
            margin-right: 0;
          }
        }

        &--title {
          font-family: var(--ff-secondary);
          font-style: normal;
          font-weight: 700;
          font-size: var(--fs-small);
          line-height: 16px;
          color: #BBBBBB;
          @include m_query($boo-md) {
            margin: 0.25rem 0;
          }
        }

        &:hover {
          filter: drop-shadow(0px 14px 15px rgba(0, 0, 0, 0.35));

          &>img {
            opacity: 1;
          }

          & .slider_main__more_news--notice--title {
            color: var(--c-text-white);
          }

          & .slider_main--dates {
            color: var(--c-text-white);
          }
        }
      }
    }

    &--dates {
      font-family: var(--ff-secondary);
      font-weight: 600;
      font-size: var(--fs-small);
      line-height: 16px;
      color: var(--c-text-white);

      @include m_query($boo-md) {
        font-weight: 300;
      }
    }

    & hr {
      color: var(--c-text-white);
      opacity: 0.4;
    }
 }


 &_social {
  background: linear-gradient(180deg, #37393A -7.31%, #151617 100%);

  &__items {
    gap: 1rem;

    li {
      width: 400px !important;
      height: 220px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    &-visit {
      opacity: 0;
    }

    &-title {
      opacity: 1;
    }

    & h3 {
      position: absolute;
      width: 100%;
      transition: all 500ms ease;
    }

    & img, svg {
      height: 50px;
      transform: scale(0.7);
      transition: all 700ms ease-in-out;
    }

    &:hover {

      & .slider_social__item-visit {
        opacity: 1;
        transition: all 500ms ease;
      }

      .slider_social__item-title {
        opacity: 0;
        transition: all 500ms ease;
      }

      & img, svg {
        transform: scale(1);
        transition: all 700ms ease-in-out;
      }
    }

    &-fb {
      background: var(--bg-facebook);

      &:hover {
        background: var(--gd-facebook);
      }
    }

    &-tw {
      background: var(--bg-twitter);

      &:hover {
        background: var(--gd-twitter);
      }
    }

    &-ig {
      background: var(--gd-instagram);

      &:hover {
        background: var(--gd-instagram-hover);
      }
    }

    &-yt {
      background: var(--bg-youtube);

      &:hover {
        background: var(--gd-youtube);
      }
    }

    &-in {
      background: var(--bg-linkedin);

      &:hover {
        background: var(--gd-linkedin);
      }
    }

    &-wp {
      background: var(--bg-whatsapp);

      &:hover {
        background: var(--gd-whatsapp);
      }
    }

    &-tg {
      background: var(--bg-telegram);

      &:hover {
        background: var(--gd-telegram);
      }
    }

    &-sp {
      background: var(--bg-spotify);

      &:hover {
        background: var(--gd-spotify) ;
      }
    }

    &-tk {
      background: var(--bg-tiktok);

      &:hover {
        background: var(--gd-tiktok) ;
      }
    }
    
    &-ggn {
      background: var(--bg-google);

      &:hover {
        background: var(--gd-google);
      }
    }
  }
}


&_notes {

  background: var(--gd-gray);
  position: relative;
   & > img {
    clip-path: inset(0px 0px 30% 0px);
   }

  & .is-prev {
    opacity: 0;
    transition: all 300ms ease;
  }

  &__item {
    padding: 2rem;
    background: var(--gd-black);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  &__slide{

    &--content {
      background: var(--gd-black);
      height: 100%;
      width: 100%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &-hover {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 500ms ease;
    }

    &:hover{
      .slider_notes__slide-hover {
        opacity: 1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        transition: all 500ms ease;
      }
    }
  }
}


&_gallerys {

  &__button {
    justify-content: space-between;

    .splide__arrow {
      background: transparent !important;
      height: 2.5rem !important;
      position: inherit;
      top: 0%;
      transform: none;
      box-shadow: none;

      & > svg {
        fill: #FFF !important;
      }

      &:hover {
        background: #FFF !important;

        & > svg {
          fill: #760208 !important;
        }
      }
    }
  }
 }

 &_video {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;

  &--color {
    &-blue {
      background: var(--gd-intense-blue);
    }
    &-red {
      background: var(--gd-red-intense);
    }
  }

  & .btn-play {
    transform: scale(1);
    transition: all 900ms ease-in-out;
  }

  .splide__slide:hover {
    background: #FFF;

    & h3 {
      color: #222;
    }

    & .dates {
      color: #222;
    }

    & .btn-play {
      transform: scale(1.2);
      transition: all 900ms ease-in-out;
    }
  }
}

}

// Clasesitas de splide
.splide__track--nav>.splide__list>.splide__slide.is-active {
  border: 3px solid transparent !important;

  .slider_main__more_news--notice img {
    opacity: 1;
  }

  .slider_main__more_news--notice--title {
    color: #FFF;
    opacity: 1;
  }

  .slider_main--dates {
    color: #FFF;
    opacity: 1;
  }
}

#thumbnail-carousel {

  & .splide__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.875rem;
    padding: 0 2rem !important;

    @include m_query($boo-sm) {
      padding: 0 1rem !important;
      gap: 0.625rem;
   }
  }

  & .splide__slide {
    height: 100% !important;
    width: 100% !important;
  }
}


.thumbnail__gallery {
  &__button {
    .splide__arrow {
      background: transparent !important;
      height: 2.5rem !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: none;

      &--prev {
        left: 1em !important;
      }

      &--next {
        right: 1em !important;
      }

      & > svg {
        fill: #FFF !important;
      }

      &:hover {
        background: #FFF !important;

        & > svg {
          fill: #760208 !important;
        }
      }
    }
  }
}

.splide__arrow {
  background: #FFF !important;
  height: 2.75rem !important;
  width: 2rem !important;
  opacity: 1 !important;
  border-radius: 0% !important;
  box-shadow: 0px 8px 25px rgba(0,0,0,0.7);
  transition: all 700ms ease;

  & > svg {
      fill: #015287 !important;
      transition: all 700ms ease;
  }

  &--prev {
      left: 4rem !important;
  }

  &--next {
      right: 4rem !important;
  }

  &:hover {
      background: #760208 !important;
      box-shadow: 0px 16px 25px #000000;
      transition: all 700ms ease;

      & > svg {
          fill: #FFF !important;
          transition: all 700ms ease;
      }
  }
}

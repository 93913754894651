@use 'sass:math';

@mixin m_query($width, $type: 'max'){
    $width_value: trim_unit($width);
    $width_unit: unit($width);
    $width_pixels: 0;
    $width_bp: 0;
    $rem_value: 16;

    //Detect unit
    @if $width_unit == 'rem' or $width_unit == 'em'{
        $width_pixels: $width_value * $rem_value;
    }@else{
        $width_pixels: $width_value;
    }

    @if $type == 'max'{

        $width_bp: math.div($width_pixels, $rem_value) * 1em;
        @media screen and (max-width: $width_bp){
            @content;
        }

    }@else{

        //@see https://www.w3.org/TR/mediaqueries-4/#mq-min-max
        $width_bp: math.div($width_pixels + .02, $rem_value) * 1em;
        @media screen and (min-width: $width_bp){
            @content;
        }
    }

}

/** Based in: https://codepen.io/MadeByMike/pen/vNrvdZ?editors=0110 */
@use 'sass:math';

$rem_base_px: 16;
$fix_safari: true !default;

@function trim_unit($value) {
    @return math.div($value, $value * 0 + 1);
}

@function from_base_px($value) {
    @return math.div(trim_unit($value), $rem-base-px);
}

@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value, $is_important: null) {

    $min-screen_unit: unit($min-screen);
    $max-screen_unit: unit($max-screen);
    $min-value_unit: unit($min-value);
    $max-value_unit: unit($max-value);

    /* ● Comprobacion de unidades ❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱ */

    @if $min-screen_unit !=$max-screen_unit {
        @error "Los unidades para $min-screen y $max-screen son diferentes. Puede utilizar 'em', 'px' o 'rem', pero no combinadas.";
    }

    @if $min-value_unit !=$max-value_unit {
        @error "Los unidades para $min-value y $max-value son diferentes. Puede utilizar 'em', 'px' o 'rem', pero no combinadas.";
    }

    $max-screen-em: $max-screen;
    $min-screen-em: $min-screen;

    /* ● Convertir los tamaños de pantalla para media querys a 'em' ❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱ */
    /** @see https://zellwk.com/blog/media-query-units/ */

    /** Si usamos 'rem', las convertimos en 'em' */
    @if $min-screen_unit==rem and $max-screen_unit==rem {
        $max-screen-em: trim_unit($max-screen-em) * 1em;
        $min-screen-em: trim_unit($min-screen-em) * 1em;
    }

    /** Si usamos 'px', las convertimos en 'em' */
    @if $min-screen_unit==px and $max-screen_unit==px {
        $max-screen-em: from_base_px($max-screen) * 1em;
        $min-screen-em: from_base_px($min-screen) * 1em;
    }


    & {
        @each $property in $properties {
            #{$property}: $min-value $is_important;

        // Include safari iframe resize fix if needed

        /*-clean-css ignore:start */
        min-width: if($fix_safari, 0vw, null);
        /*-clean-css ignore:end */

        }

        @media screen and (min-width: $min-screen-em) {
            @each $property in $properties {
                #{$property}: calc-interpolation($min-screen, $max-screen, $min-value, $max-value) $is_important;
            }
        }

        @media screen and (min-width: $max-screen-em) {
            @each $property in $properties {
                #{$property}: $max-value $is_important;
            }
        }
    }
}

// Requires the calc-interpolation function which can also be used independently

@function calc-interpolation($min-screen, $max-screen, $min-value, $max-value) {

    $min-screen_unit: unit($min-screen);
    $max-screen_unit: unit($max-screen);
    $min-value_unit: unit($min-value);
    $max-value_unit: unit($max-value);

    /* ● Tamaños de pantalla ❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱ */

    /** Si usamos 'rem', convertimos los tamaños de pantalla a 'rem' */
    @if $min-value_unit==rem and $max-value_unit==rem {

        @if $min-screen_unit==em and $max-screen_unit==em {
            $max-screen: trim_unit($max-screen) * 1rem;
            $min-screen: trim_unit($min-screen) * 1rem;
        }

        @else if $min-screen_unit==px and $max-screen_unit==px {
            $max-screen: from_base_px($max-screen) * 1rem;
            $min-screen: from_base_px($min-screen) * 1rem;
        }

        /** Si usamos 'px', convertimos los tamaños de pantalla a 'px' */
    }

    @else if $min-value_unit==px and $max-value_unit==px {

        @if $min-screen_unit==em and $max-screen_unit==em or $min-screen_unit==rem and $max-screen_unit==rem {
            $max-screen: trim_unit($max-screen) * $rem_base_px * 1px;
            $min-screen: trim_unit($min-screen) * $rem_base_px * 1px;
        }

        /** Si usamos 'em', convertimos los tamaños de pantalla a 'em' */
    }

    @else if $min-value_unit==em and $max-value_unit==em {
        @if $min-screen_unit==rem and $max-screen_unit==rem {
            $max-screen: trim_unit($max-screen) * 1em;
            $min-screen: trim_unit($min-screen) * 1em;

        }

        @else if $min-screen_unit==px and $max-screen_unit==px {
            $max-screen: from_base_px($max-screen) * 1em;
            $min-screen: from_base_px($min-screen) * 1em;
        }
    }

    $a: math.div($max-value - $min-value, $max-screen - $min-screen);
    $b: $min-value - $a * $min-screen;

    $sign: "+";

    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }

    @return calc(#{$a*100}vw #{$sign} #{$b});
}

/* ● SHORTHANDS MIXINS HELPERS ❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱❱ */

$min_scren_size: 320px;
$max_scren_size: 1440px;

@mixin rfs($propiety, $min_s, $max_s, $is_import: null){
    @include interpolate($propiety, $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin font-size($min_s, $max_s, $is_import: null){
    @include interpolate('font-size', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin margin($min_s, $max_s, $is_import: null){
    @include interpolate('margin', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin margin-top($min_s, $max_s, $is_import: null){
    @include interpolate('margin-top', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin margin-left($min_s, $max_s, $is_import: null){
    @include interpolate('margin-left', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin margin-right($min_s, $max_s, $is_import: null){
    @include interpolate('margin-right', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin margin-bottom($min_s, $max_s, $is_import: null){
    @include interpolate('margin-bottom', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin padding($min_s, $max_s, $is_import: null){
    @include interpolate('padding', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin padding-top($min_s, $max_s, $is_import: null){
    @include interpolate('padding-top', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin padding-right($min_s, $max_s, $is_import: null){
    @include interpolate('padding-right', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin padding-left($min_s, $max_s, $is_import: null){
    @include interpolate('padding-left', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

@mixin padding-bottom($min_s, $max_s, $is_import: null){
    @include interpolate('padding-bottom', $min_scren_size, $max_scren_size, $min_s, $max_s, $is_import);
}

/*  |> BREAKPOINTS BOOSTRAP
——————————————————————————————————————————————————————*/
$boo-xs: 22.5em; // 360px
$boo-sm: 36em; // 576px
$boo-md: 48em; //768px
$boo-lg: 62em; //992px
$boo-xl: 75em; //1200px
$boo-1xl: 79.938em; //1280px

$tw-3xl: 100rem; // 1600px

$enable-button-pointers: true !default;

$kbd-padding-y: .5rem;
$kbd-padding-x: .5rem;
$kbd-color: grey;
$kbd-bg: white;
$line-sizes: 1,2,3,4,5,6,7,8;
$section-colors: (
    'blue': #015287,
    'gray': #77848C,
    'yellow': #ECC023,
    'orange': #FFA231,
    'red': #AA0006,
    'dark_blue': #1C3E5C,
    'sky_blue': #155A61,
    'emerald_green': #09BE65,
    'dark_purple': #7F3470,
    'denim': #4E889B,
    'red_wine': #9E0B0B,
    'cream': #E3BC8B,
    'cyan': #035DC6,
    'green': #0C5F1A,
    'purple': #620BD1,
    'pink': #E90448,
    'mint_green': #1BEBB9,
    'brown': #492D1C,
    'electric_blue': #0A3299,
);
$box-shadow-size: 0px 8px 25px;

:root {
  --p-container: 7rem; //Padding Container
  --max-width-container: calc(1320px + var(--p-container)); // Add the corresponding padding used to compensate for the width.

  /*
  ——— Fonts
 */
  --ff-primary: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --ff-secondary: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --ff-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --body-font-size: 1rem;
  --body-line-height: 1.5;
  --ff-size-18: 1.125rem;

  /*
 ——— Colors
 */
  --c-body-bg: #F0F4F8;
  --c-primary-red: #AA0006;
  --c-bg-hover: #232962;
  --c-text-white: #FFFFFF;
  --bg-blue-menu: #002546;

  /*
 ——— HR
 */
  --hr-color: grey;
  --hr-opacity: 0.4;

  /*
 ——— Mark
 */
  --mark-padding: .5rem;
  --mark-bg: yellow;

  /*
 ——— Scrolling
 */
  scroll-behavior: smooth;

  /* Font sizes */
  @include rfs("--fs-small", 0.75rem, 0.75rem); //12px
  @include rfs("--fs-1", 1rem, 1.125rem); //18px
  @include rfs("--fs-2", 1.125rem, 1.25rem); //20px
  @include rfs("--fs-3", 1.25rem, 1.5rem); //24px
  @include rfs("--fs-4", 1.5rem, 1.875rem); //30px
  @include rfs("--fs-5", 1.5rem, 2rem); //34px
  @include rfs("--fs-6", 1.5rem, 2.375rem); //40px
  @include rfs("--fs-7", 2.5rem, 3.25rem); //52px

  /*
  ——— Spacing
  */
  @include rfs("--sp-40", 2rem, 2.5rem); //40px
  @include rfs("--sp-64", 2rem, 4rem); //64px
  @include rfs("--sp-80", 2.5rem, 5rem); //80px
  @include rfs("--sp-100", 2.5rem, 6.25rem); //100px


  /*
  ——— Border Radius
  */
  @include rfs("--br-15", 8px, 15px); //15px

  /*
  ——— Gradients
  */
  --gd-blue: linear-gradient(180deg, #013059 -7.31%, #051B2F 68.7%);
  --gd-blue-two: linear-gradient(180deg, rgba(0, 128, 211, 0) -6.74%, #001624 67.2%), #015287;
  --gd-intense-blue: linear-gradient(180deg, #02335F -7.31%, #051B2F 100%);
  --gd-intense-blue-v3: linear-gradient(180deg, #015287 -6.74%, #232962 100%), #E12028;
  --gd-intense-blue-multimedia: linear-gradient(180deg, rgba(22, 101, 118, 0) -6.74%, #03103F 73.87%), #0096C6;
  --gd-red: linear-gradient(180deg, rgba(118, 22, 27, 0) -6.74%, rgba(95, 14, 18, 0.411803) 61.88%, #3F0306 100%), #646464;
  --gd-red-two: linear-gradient(180deg, rgba(118,22,27,0) -6.74%, #3F0306 73.87%), #E12028;
  --gd-red-intense: linear-gradient(180deg, #76161B -6.74%, #3F0306 100%);
  --gd-red-intense-two: linear-gradient(180deg, #E12028 16.36%, #B11C22 51.31%, #72060A 100%);
  --gd-light-blue: linear-gradient(180deg, rgba(0, 150, 198, 0) -6.74%, rgba(20, 89, 142, 0.56102) 70.66%, #232962 100%, #013059 100%), #646464;
  --gd-black: linear-gradient(180deg, rgba(0, 0, 0, 0) 14.09%, rgba(0, 0, 0, 0.568) 38.85%, rgba(0, 0, 0, 0.8) 61.52%);
  --gd-black-sm: linear-gradient(179.59deg, rgba(0, 0, 0, 0) 85.36%, rgba(0, 0, 0, 0.568) 93.43%, rgba(0, 0, 0, 0.8) 99.24%);
  --gd-gray: linear-gradient(180deg, #37393A -7.31%, #151617 100%);
  --gd-dark-blue: linear-gradient(180deg, #013059 -7.31%, #051B2F 68.7%), #FFFFFF;
  --gd-dark-red: linear-gradient(to top, #E2AC6A -55.79%, #B0342B -3.57%, #8E1E1D 65.19%, #3B0407 100%), #FFFFFF;
  --gd-side-shadow: linear-gradient(90deg, #1B1A1A 0%, #222222 41.55%, rgba(34, 34, 34, 0) 55.58%, rgba(34, 34, 34, 0.530035) 75.24%, #222222 79%, #1B1A1A 84.41%);

    /*
  ——— Social Networks
  */
  --bg-facebook: #3B5998;
  --gd-facebook: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #3B5998;
  --bg-twitter: #000;
  --gd-twitter: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #000;
  --gd-instagram: radial-gradient(127.25% 127.25% at 0% 118.71%, #FED372 0%, #F86F2F 26.56%, #D43089 52.08%, #9E37B8 75.52%, #5F4BC6 100%);
  --gd-instagram-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), radial-gradient(127.25% 127.25% at 0% 118.71%, #FED372 0%, #F86F2F 26.56%, #D43089 52.08%, #9E37B8 75.52%, #5F4BC6 100%);
  --bg-youtube: #FF0000;
  --gd-youtube: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #FF0000;
  --bg-linkedin: #0A66C2;
  --gd-linkedin: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #0A66C2;
  --bg-telegram: #0088CC;
  --gd-telegram: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #0088CC;
  --bg-whatsapp: #48ec64;
  --gd-whatsapp: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #48ec64;
  --bg-spotify: #1DB954;
  --gd-spotify: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), #1DB954;
  --bg-tiktok: linear-gradient(18deg, rgba(255,0,80,1) 13%, rgba(0,255,247,1) 100%);
  --gd-tiktok: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), linear-gradient(18deg, rgba(255, 0, 80, 1) 13%, rgba(0, 255, 247, 1) 100%);
  --bg-google: #FFF;
  --gd-google: rgba(219, 217, 217,03);
  
  ;
  /*
  ——— Icons
  */
  --icon-arrow: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M19 7L11.7929 14.2071L10.7071 13.2929L4.41421 7L3 8.41421L11.7071 17.1213L20.4142 8.41421L19 7Z' fill='black'/%3e %3c/svg%3e");
  --icon-arrow-slider: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.36728 8.29994L0.592285 14.0749L2.24195 15.7246L9.66662 8.29994L2.24195 0.875276L0.592285 2.52494L6.36728 8.29994Z' fill='%2321BAB3'/%3E%3C/svg%3E%0A");
  --icon-arrow-slider--white: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.36728 8.29994L0.592285 14.0749L2.24195 15.7246L9.66662 8.29994L2.24195 0.875276L0.592285 2.52494L6.36728 8.29994Z' fill='white'/%3E%3C/svg%3E%0A");
  --icon-facebook: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z' fill='black'/%3e %3c/svg%3e");
  --icon-instagram: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12 2.667c2.536 0 2.852.009 3.847.056.994.046 1.67.202 2.266.434a4.551 4.551 0 0 1 1.654 1.076 4.58 4.58 0 0 1 1.076 1.654c.23.594.388 1.272.434 2.266.044.995.056 1.311.056 3.847s-.009 2.852-.056 3.847c-.046.994-.203 1.67-.434 2.266a4.558 4.558 0 0 1-1.076 1.654 4.588 4.588 0 0 1-1.654 1.076c-.594.23-1.272.388-2.266.434-.995.044-1.311.056-3.847.056s-2.852-.009-3.847-.056c-.994-.046-1.67-.203-2.266-.434a4.564 4.564 0 0 1-1.654-1.076 4.577 4.577 0 0 1-1.076-1.654c-.232-.594-.388-1.272-.434-2.266-.044-.995-.056-1.311-.056-3.847s.009-2.852.056-3.847c.046-.995.202-1.67.434-2.266.233-.624.6-1.188 1.076-1.654a4.57 4.57 0 0 1 1.654-1.076c.595-.232 1.27-.388 2.266-.434.995-.044 1.311-.056 3.847-.056Zm0 4.666a4.667 4.667 0 1 0 0 9.334 4.667 4.667 0 0 0 0-9.334Zm6.067-.233a1.167 1.167 0 1 0-2.334 0 1.167 1.167 0 0 0 2.334 0ZM12 9.2a2.8 2.8 0 1 1 0 5.6 2.8 2.8 0 0 1 0-5.6Z' fill='black'/%3e %3c/svg%3e");
  --icon-twitter: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M22.162 5.656a8.385 8.385 0 0 1-2.402.658A4.195 4.195 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.211 4.211 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.395 8.395 0 0 1-6.19 1.732 11.83 11.83 0 0 0 6.409 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.495 8.495 0 0 0 2.087-2.165v-.001Z' fill='black'/%3e %3c/svg%3e");
  --icon-youtube: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022ZM10 15.5l6-3.5-6-3.5v7Z' fill='black'/%3e %3c/svg%3e");
  --icon-location: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M13.773 13.023L9 17.796L4.227 13.023C3.28301 12.079 2.64014 10.8763 2.3797 9.56689C2.11925 8.25752 2.25293 6.90032 2.76382 5.66693C3.27472 4.43353 4.13988 3.37933 5.24991 2.63764C6.35994 1.89594 7.66498 1.50006 9 1.50006C10.335 1.50006 11.6401 1.89594 12.7501 2.63764C13.8601 3.37933 14.7253 4.43353 15.2362 5.66693C15.7471 6.90032 15.8808 8.25752 15.6203 9.56689C15.3599 10.8763 14.717 12.079 13.773 13.023ZM9 11.25C9.79565 11.25 10.5587 10.9339 11.1213 10.3713C11.6839 9.80871 12 9.04565 12 8.25C12 7.45435 11.6839 6.69129 11.1213 6.12868C10.5587 5.56607 9.79565 5.25 9 5.25C8.20435 5.25 7.44129 5.56607 6.87868 6.12868C6.31607 6.69129 6 7.45435 6 8.25C6 9.04565 6.31607 9.80871 6.87868 10.3713C7.44129 10.9339 8.20435 11.25 9 11.25Z' fill='black'/%3e %3c/svg%3e");
}

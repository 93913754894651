
.program {
  &__title {
    opacity: 1;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  &__description {
    position: absolute;
    font-family: var(--ff-secondary);
    font-weight: 400;
    font-size: 0.9375em;
    line-height: 20px;
    color: #222;
    opacity: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;

    @include m_query($boo-lg) {
      font-size: 0.75em;
    }
  }

  &:hover {
    -webkit-filter: drop-shadow(0px 14px 43px rgba(0, 0, 0, 0.18));
    filter: drop-shadow(0px 14px 43px rgba(0, 0, 0, 0.18));

    .program__title {
      opacity: 0;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }

    .program__description {
      opacity: 1;
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
    }

    .program__img {
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 47.27%, rgba(0, 0, 0, 0.355) 78.5%, rgba(0, 0, 0, 0.5) 98.57%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 47.27%, rgba(0, 0, 0, 0.355) 78.5%, rgba(0, 0, 0, 0.5) 98.57%);
    }
  }
}

.opinion {
  background-color: #061C30;
};

.section_header {
  &__content {
    &-subtitle {
      font-size: 3.125em;
      line-height: 45px;
      letter-spacing: 0.1em;

      @include m_query($boo-sm) {
        font-size: 2em;
      }

      @include m_query($boo-xs) {
        font-size: 1.5em;
      }
    }

    &-title {
      font-size: 1.75em;
      line-height: 1.25rem;
    }

    &-box_shadow {
      background: #ffffff82;
      box-shadow: 0px -10px 32px 0px rgba(249, 249, 249, 0.89);
      -webkit-box-shadow: 0px -10px 32px 0px rgba(249, 249, 249, 0.89);
      -moz-box-shadow: 0px -10px 32px 0px rgba(249, 249, 249, 0.89);
    }
  }
  // &__tags{
  //   @include m_query($boo-xs) {
  //     flex-direction: column;
  //     svg {
  //       display: none
  //     }
  //   }
  // }

  &__tags-list {
    & > :not(:first-child)::before {
      content: "•";
      margin-bottom: 3px;
      margin-right: 4px;
    }

    @include m_query ($boo-sm) {
      & > :first-child::before {
        content: "•";
        margin-bottom: 3px;
        margin-right: 4px;
      }
    }
  }

  &__tag {
    gap: 0.25rem;
    display: inline-flex;
    align-items: center;
    @apply font-ff-primary;
    line-height: 25px;
    margin-right: 0.25rem;
    min-width: fit-content;

    @include m_query($boo-xs) {
      list-style: disc;
    }
  }
}

.category {
  @apply text-white;
  font-weight: 600;
  width: fit-content;
  font-size: 12px;
  text-transform: uppercase;

  &--size-md {
    padding: 0.1rem 0.840rem;
  }

  &--color {

    &-blue {
      -webkit-box-shadow: $box-shadow-size rgba(1, 82, 135, 0.7);
      box-shadow: $box-shadow-size rgba(1, 82, 135, 0.7);
    }

    &-gray {
      -webkit-box-shadow: $box-shadow-size rgba(119, 132, 140, 0.7);
      box-shadow: $box-shadow-size rgba(119, 132, 140, 0.7);
    }

    &-yellow {
      -webkit-box-shadow: $box-shadow-size rgba(236, 192, 35, 0.7);
      box-shadow: $box-shadow-size rgba(236, 192, 35, 0.7);
    }

    &-orange {
      -webkit-box-shadow: $box-shadow-size rgba(255, 162, 49, 0.7);
      box-shadow: $box-shadow-size rgba(255, 162, 49, 0.7);
    }

    &-red {
      -webkit-box-shadow: $box-shadow-size rgba(170, 0, 6, 0.7);
      box-shadow: $box-shadow-size rgba(170, 0, 6, 0.7);
    }

    &-dark_blue {
      -webkit-box-shadow: $box-shadow-size rgba(28, 62, 92, 0.7);
      box-shadow: $box-shadow-size rgba(28, 62, 92, 0.7);
    }

    &-sky_blue {
      -webkit-box-shadow: $box-shadow-size rgba(21, 90, 97, 0.7);
      box-shadow: $box-shadow-size rgba(21, 90, 97, 0.7);
    }

    &-emerald_green {
      -webkit-box-shadow: $box-shadow-size rgba(9, 190, 101, 0.7);
      box-shadow: $box-shadow-size rgba(9, 190, 101, 0.7);
    }

    &-dark_purple {
      -webkit-box-shadow: $box-shadow-size rgba(127, 52, 112, 0.7);
      box-shadow: $box-shadow-size rgba(127, 52, 112, 0.7);
    }

    &-denim {
      -webkit-box-shadow: $box-shadow-size rgba(78, 136, 155, 0.7);
      box-shadow: $box-shadow-size rgba(78, 136, 155, 0.7);
    }

    &-red_wine {
      -webkit-box-shadow: $box-shadow-size rgba(158, 11, 11, 0.7);
      box-shadow: $box-shadow-size rgba(158, 11, 11, 0.7);
    }

    &-cream {
      -webkit-box-shadow: $box-shadow-size rgba(227, 188, 139, 0.7);
      box-shadow: $box-shadow-size rgba(227, 188, 139, 0.7);
    }

    &-cyan {
      -webkit-box-shadow: $box-shadow-size rgba(3, 93, 198, 0.7);
      box-shadow: $box-shadow-size rgba(3, 93, 198, 0.7);
    }

    &-green {
      -webkit-box-shadow: $box-shadow-size rgba(12, 95, 26, 0.7);
      box-shadow: $box-shadow-size rgba(12, 95, 26, 0.7);
    }

    &-purple {
      -webkit-box-shadow: $box-shadow-size rgba(98, 11, 209, 0.7);
      box-shadow: $box-shadow-size rgba(98, 11, 209, 0.7);
    }

    &-pink {
      -webkit-box-shadow: $box-shadow-size rgba(233, 4, 72, 0.7);
      box-shadow: $box-shadow-size rgba(233, 4, 72, 0.7);
    }

    &-mint_green {
      -webkit-box-shadow: $box-shadow-size rgba(27, 235, 185, 0.7);
      box-shadow: $box-shadow-size rgba(27, 235, 185, 0.7);
    }

    &-brown {
      -webkit-box-shadow: $box-shadow-size rgba(187, 91, 68, 0.7);
      box-shadow: $box-shadow-size rgba(187, 91, 68, 0.7);
    }

    &-electric_blue {
      -webkit-box-shadow: $box-shadow-size rgba(35, 80, 198, 0.7);
      box-shadow: $box-shadow-size rgba(35, 80, 198, 0.7);
    }
    &-bg-gray {
      background: var(--gd-gray);
    }
  }

  &--color-red_most_reading {
    background: var(--gd-red-intense-two);
  }

  &--color-multimediaads {
    background: var(--gd-intense-blue-multimedia);
  }

  &__audio {
    background: #61AF07;
    box-shadow: $box-shadow-size rgba(97, 175, 7, 0.7);
  }

  &__video {
    background: #7F3470;
    -webkit-box-shadow: $box-shadow-size rgba(127, 52, 112, 0.7);
    box-shadow: $box-shadow-size rgba(127, 52, 112, 0.7);
  }
  &__image {
    background: #015287;
    -webkit-box-shadow: $box-shadow-size rgba(1, 82, 135, 0.7);
    box-shadow: $box-shadow-size rgba(1, 82, 135, 0.7);
  }
  &__map {
    background: #E3BC8B;
    -webkit-box-shadow: $box-shadow-size rgba(227, 188, 139, 0.7);
    box-shadow: $box-shadow-size rgba(227, 188, 139, 0.7);
  }
}

.dates {
  @apply font-ff-secondary;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;

  &--text-white {
    @apply text-white;
  }

  &--text-black {
    @apply text-black;
  }
}

@media only screen and (max-width: 1024px) {
  .category--color-multimediaads {
    display: none;
  }
}

.btn-blue {
    background: #015287;
    box-shadow: 0px 8px 25px rgba(1, 82,135,0.7);
    padding: 0.375rem 1.125rem;
}

.btn-red {
    background: #CE333B !important;
    padding: 1rem 3.75rem;
    text-align: center;
    color: #FFF;
    font-family: var(--ff-primary);
    font-size: 1.125em;
    font-weight: 600;
    text-transform: uppercase;

    @include m_query($boo-sm) {
      padding: 0.5rem 1.75rem;
      font-size: 1em;
    }
}

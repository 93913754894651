.media__section .media {
  max-height: 17.5rem;
}

.media {
  position: relative;
  max-width: 60.625rem;
  max-height: 15.625rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }

  &.multimedia {
    &::after {
      background: var(--gd-red-two);
    }
  }

  &.audio {
    &::after {
      background: var(--gd-blue-two);
    }
  }

  &.video_list {
    order: 1;
    &::after {
      background: var(--gd-intense-blue-multimedia);
    }
  }

}


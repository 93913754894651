.alert-success {
    color: #225424;
    background-color: #d9ecda;
    border-color: #cae5cb;
}

.alert-error {
    color: #781e12;
    background-color: #fad8d3;
    border-color: #f8c8c1;
}

.alert {
    position: relative;
    padding: 12px 20px;
    padding: 0.75rem 1.25rem;
    border: 0 solid transparent;
    border-radius: 0;
    font-family: var(--ff-primary);
    font-size: 1.5rem;
    font-weight: 600;
  }
.social__blue {
    & .social-media__icon {
        & svg {
            fill: #003667;
        }
    }
}

.social__white {
    & .social-media__icon {
        & svg {
            fill: #FFF;
        }
    }
}

.social-media__icon {
    width: 1.375rem;
    height: 1.25rem;
    
    @include m_query(80rem) {
        width: 1rem;
        height: 1rem;
    }

    & svg, img {
        width: 100%;
        height: 100%;
    }
}
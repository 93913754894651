.dual_video {
  height: 100%;
  width: 100%;


  &--bg-red {
    background: linear-gradient(180deg, rgba(118,22,27,0) -6.74%, #3F0306 100%), #E12028;
  }

  &--container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;

    &--cover {
      background: linear-gradient(180deg, #E12028 16.36%, #B11C22 51.31%, #72060A 100%);
      padding: 0.75rem;

      & > label {
        font-family: var(--ff-primary);
        font-style: normal;
        font-weight: 600;
        font-size: var(--fs-1);
        line-height: 23px;
        text-transform: uppercase;
        color: #FFF;
      }
    }

    &--otherVideos {
      &-imgGradiend {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.12%, rgba(14, 14, 14, 0.5) 87.89%);
        z-index: 1;
        min-width: 9.688rem;
        height: 6rem;
        @include m_query($boo-xs) {
          min-width: 6.688rem;
          height: 5rem;
        }
        & > img {
          transition: all 0.8s ease;
          position: relative;
          object-fit: cover;
          z-index: -1;
          width: 100%;
          height: 100%;
        }
      }


      & > a {
        column-gap: 21px;
      }

      &--description {
        & > h3 {
          font-family: var(--ff-secondary);
          font-style: normal;
          font-weight: 700;
          font-size: 1em;
          @include m_query($boo-xs) {
            font-size: 0.875em;
          }
          line-height: 20px;
          color: #FFF;
        }

        & > span {
          font-family: var(--ff-secondary);
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1em;
          color: #FFFF;
        }
      }

      &:hover {
        & img {
          transition: all 0.6s ease;
          filter: brightness(50%);
        }
      }
    }
  }

  .bluevideo {
    background: var(--gd-intense-blue-v3);

    & .social-media__icon {
      width: 100% !important;
      height: 1.25rem !important;
      display: flex;

      & a {
        margin: 0 auto;
      }
    }

    &__point {
      &-white {
        position: absolute;
        opacity: 1;
        width: 15px;
        top: 0px;
        left: 0px;
      }

      &-red {
        position: absolute;
        opacity: 0;
        width: 15px;
        top: 0px;
        left: 0px;
      }
    }

    &__title {
      border-left: 1px solid #FFF;
      margin-left: 0.5rem;
      padding-left: 1.5rem;
    }

    &__container {
      position: relative;
      &:hover {
        filter: drop-shadow(0px 14px 15px rgba(0, 0, 0, 0.35));

        .bluevideo__point-red {
          opacity: 1;
          transition: all 200ms ease;
        }

        .bluevideo__point-white {
          opacity: 0;
          transition: all 200ms ease;
        }

        .bluevideo__title {
          border-left: 1px solid #E12028;
        }
      }
    }
  }

}

.widget{
  &-video-stream{
    & iframe{
      width: 100% !important;
    }
  }
}

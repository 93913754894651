.pagination {


    &__item {
        @apply font-ff-secondary;
        @apply font-semibold;
        @apply text-gray-300;
        font-size: 1.125em;
        line-height: 25px;
        padding: 3px 10px;
      &.active {
        @apply bg-primary-dark-blue;
        @apply text-white;
      }
    }
}
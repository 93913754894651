.trim_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @each $size in $line-sizes {
    &--line-size-#{$size} {
      -webkit-line-clamp: $size;
    }
  }

  &.breadcrumb_text.trim_text--line-size-1 {
    -webkit-line-clamp: 1;

    @media only screen and (max-width: 640px) {
      -webkit-line-clamp: 2;
    }
  }

  // for safari
  &.trim_text--line-size-3 {
    -webkit-line-clamp: 3;
  }
}

.gd_dark_red {
  background: var(--gd-dark-red);
}

@each $key, $value in $section-colors {
    .bg-section-#{$key} {
      background: $value;
    }

    .text-section-#{$key} {
      color: $value;
    }

    .border-section-#{$key} {
      border-color: $value;
    }
}

.max-h-65 {
  max-height: 65px;

  @include m_query($boo-md) {
    max-height: 50px;
  }
}
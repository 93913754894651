.footer {
  background: var(--gd-blue);
  padding: 3.125rem 0;

  @apply text-white;

  &__logo-info {
    max-width: 214px;
    margin-bottom: 1rem;
  }

  &__nav {
    max-width: 580px;

    ul {
      li {
        font-weight: 600;
        line-height: 20px;
        @include m_query($boo-sm) {
          font-size: 0.875em;
        }
      }
    }
  }

  &__sections-links {
    ul {
      li {
        font-size: 1.125em;
        margin-bottom: 1rem;
        @include m_query($boo-sm) {
          font-size: 0.875em;
        }
      }
    }
  }

  &__hr {
    height: 0.4px;
    opacity: 0.4;
  }

  &__sections-wrapper {
    width: calc(100% - 130px);
    
    @include m_query($boo-1xl) {
      width: 100%;
      flex-direction: column;
    }
  }

  &__social-media {
    max-width: 332px;
     
    @include m_query($boo-1xl) {
      max-width: 100%;
    }
  }

  .social-media__icon  {
    @include m_query(30.625rem) {
      width: 100%;
    }
  }
}

.section {
  &__container {
    width: calc(70% - 140px);

    @include m_query($boo-1xl) {
      width: 100%;
    }
  }

  &__title {
    font-size: 1.625em;
  }
}
